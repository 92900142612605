import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/RepoView.vue'),
        props: true
      }, {
        path: 'project/:project_slug',
        name: 'SingleProject',
        component: () => import('../views/SingleProjectView.vue'),
        props: true
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
