<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'App',
  mounted () {
    console.log('ready')
    if (process.env.NODE_ENV.indexOf('dcals') >= 0) $('body').addClass('dcals')
  }
}
</script>

<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'video.js/dist/video-js.min.css';
@import '@/assets/scss/style.scss';
@import '@/assets/scss/dcals.scss';

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background: #e8e8e8;
}
</style>
